import Account from "./components/Account";
import "./App.css";
import Wallet from "./components/Wallet";
import { useState, useEffect } from "react";
import Auth from "./components/Auth";
import Admin from "./components/Admin";

function App() {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [logedIn, setLogedIn] = useState(false);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (userInfo.email === "test123@swave.kr") {
      setAdmin(true);
      return;
    }

    if (userInfo.account_id) {
      setLogedIn(true);
    } else {
      setLogedIn(false);
    }
  }, [userInfo]);

  window.location.pathname === "/oauth/kakao/callback" && Auth();

  return (
    <div className="App">
      {/* {admin ? (
        <Admin setAdmin={setAdmin} />
      ) : logedIn ? (
        <Wallet setLogedIn={setLogedIn} />
      ) : (
        <Account setLogedIn={setLogedIn} />
      )} */}
      {logedIn ? (
        <Wallet setLogedIn={setLogedIn} />
      ) : (
        <Account setLogedIn={setLogedIn} />
      )}
    </div>
  );
}

export default App;
