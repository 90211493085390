import { useState, useEffect, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import axios from "axios";
import Card from "./Card";
import WideCard from "./WideCard";
import { FaArrowRight } from "react-icons/fa";
import MediumCard from "./MediumCard";

const Create = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
`;

const CreateBtn = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 0;
  overflow: hidden;
  display: inline-flex;
  border-radius: 0.35rem;
  align-items: stretch;
  justify-content: center;
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .arrow {
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    padding: 0.375rem 0.75rem;
    align-items: center;
    background: rgba(0, 0, 0, 0.15);
    width: 38px;
    height: 36px;
  }
  .text {
    width: 100%;
    text-align: center;
    padding: 0.375rem 0.75rem;
  }

  &:hover {
    background-color: #2e59d9;
    border-color: #2653d4;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem;
`;

interface Balance {
  wallet_address?: string;
  wallet_balance?: { ETH?: string; WAFL?: string; WAFL_UNLOCK?: string };
}

interface Data {
  WAFL_POLY: number;
  WAFL_ETH: string;
}

interface Price {
  price: {
    coinsbit_WAFL: [{ price: string }];
    gdac_WAFL: [{ price: string }];
    azbit_WAFL: [{ price: string }];
  };
  rate: { USDT_TO_KRW: number };
}

interface MyWalletProps {
  balance: Balance;
  notExist: Boolean;
  setBalance: Dispatch<SetStateAction<{}>>;
  setNotExist: Dispatch<SetStateAction<boolean>>;
  setExpiration: Dispatch<SetStateAction<boolean>>;
}

const MyWallet = ({
  balance,
  notExist,
  setBalance,
  setNotExist,
  setExpiration,
}: MyWalletProps) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const header = {
    headers: { Authorization: `Bearer ${userInfo.access_token}` },
  };

  const [amount, setAmount] = useState<Data>();
  const [data, setData] = useState<Price>();

  const getWAFL = async () => {
    if (userInfo.wallet_address === null) return;
    const res = await axios.get(
      process.env.REACT_APP_WALLET_API + "/v1/api/wallets/balance",
      header
    );
    const data = res.data.data;
    setAmount(data);
  };

  const getMarketPrice = async () => {
    const res = await axios.get(
      "https://test-wallet-api.wafflestay.kr/v1/api/markets",
      header
    );
    const data = res.data.data;
    setData(data);
  };

  const rate = data?.rate.USDT_TO_KRW;
  const coinsbit = data?.price.coinsbit_WAFL[0];
  const gdac = data?.price.gdac_WAFL[0];
  const azbit = data?.price.azbit_WAFL[0];

  const cal = (data: any) => {
    if (data === undefined) return "";
    return Math.floor(parseFloat(data.price) * (rate || 0)).toLocaleString(
      "ko-KR"
    );
  };

  useEffect(() => {
    if (new Date(userInfo.expiration) <= new Date()) {
      setExpiration(true);
    }
    getWAFL();
    getMarketPrice();
  }, []);

  const createWallet = async () => {
    const res = await axios.post(
      process.env.REACT_APP_WALLET_API + "/v1/api/wallets",
      {},
      header
    );
    userInfo.wallet_address = res.data.data.wallet_address;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    setBalance(res.data.data);
    getWAFL();
    setNotExist(false);
  };

  return (
    <>
      <h1>My Wallet</h1>
      <Row>
        {notExist && (
          <Create onClick={createWallet}>
            <CreateBtn>
              <span className="arrow">
                <FaArrowRight />
              </span>
              <span className="text">Create Wallet</span>
            </CreateBtn>
          </Create>
        )}
        <MediumCard
          color="#8C8C8C"
          data={amount?.WAFL_ETH && parseFloat(amount.WAFL_ETH)}
        >
          이더리움 네트워크 WAFL(스왑 전)
        </MediumCard>
        <MediumCard color="#8247e5" data={amount?.WAFL_POLY}>
          폴리곤 네트워크 WAFL(스왑 후)
        </MediumCard>

        <Card data={`₩${cal(gdac)}`}>GDAC 가격</Card>
        <Card data={`₩${cal(azbit)}`}>Azbit 가격</Card>
        <Card data={`₩${cal(coinsbit)}`}>Coinsbit 가격</Card>
        {!notExist && (
          <>
            <WideCard color="#4e73df" data={userInfo.wallet_address}>
              ADDRESS
            </WideCard>
          </>
        )}
      </Row>
    </>
  );
};

export default MyWallet;
