import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import axios from "axios";

export const Card = styled.div`
  margin-bottom: 1.5rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
  color: #858796;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`;

export const Table = styled.table<{ br?: string; mt: string }>`
  width: 100%;
  margin-top: ${(props) => props.mt};
  border: 1px solid #e3e6f0;
  border-right: ${(props) => props.br || ""};
  border-bottom: 0;
  border-spacing: 0;
  border-collapse: separate;
  min-width: max-content;

  th {
    padding: 0.75rem;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
  }

  td {
    padding: 0.75rem;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    text-align: center;
  }
`;

interface List {
  transfer_id: number;
  transfer_dttm: string;
  crypto_value: string;
  in_cd: string;
  inout_cd: string;
  transfer_tx: string;
  child: [];
}

interface DepositProps {
  setExpiration: Dispatch<SetStateAction<boolean>>;
}

const Deposit = ({ setExpiration }: DepositProps) => {
  const head = ["일시", "수량", "구분"];
  const [list, setList] = useState<List[]>();

  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const header = {
    headers: { Authorization: `Bearer ${userInfo.access_token}` },
  };

  const getList = async () => {
    const res = await axios.get(
      process.env.REACT_APP_WALLET_API +
        "/v1/api/transfers?page=1&size=9999999",
      header
    );
    setList(res.data.data.list);
  };

  useEffect(() => {
    if (new Date(userInfo.expiration) <= new Date()) {
      setExpiration(true);
    }
    getList();
  }, []);

  return (
    <>
      <h1>History</h1>
      <Card>
        <CardBody>
          <div>
            <div style={{ overflow: "auto" }}>
              <div>
                <Table br="0" mt="0.375rem">
                  <thead>
                    <tr>
                      {head.map((item) => (
                        <th key={item}>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((item) => (
                      <tr key={item.transfer_id}>
                        <td>{item.transfer_dttm}</td>
                        <td>{parseInt(item.crypto_value)}</td>
                        <td>{item.inout_cd === "IN" ? "입금" : "출금"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default Deposit;
