import styled from "styled-components";

const Container = styled.div`
  line-height: 1;
  font-size: 0.8rem;
  text-align: center;
  color: #858796;
`;

const Footer = () => {
  return (
    <footer style={{ padding: "2rem 0", backgroundColor: "#fff" }}>
      <Container>
        <span>Copyright ©2022. Wafflestay</span>
      </Container>
    </footer>
  );
};

export default Footer;
