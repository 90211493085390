import { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import CryptoJS from "crypto-js";
import { Card, Container, Title, Input, Btn, LightDark } from "./Login";
import { useNavigate } from "react-router-dom";
import { smsCheck, confirm } from "../helper/api";

const InputBox = styled.div`
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  margin-bottom: 1rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
`;

const Inputs = styled.input`
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #d1d3e2;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 1.5rem 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &::placeholder {
    color: #858796;
  }
  &:focus {
    color: #6e707e;
    background-color: #fff;
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
  }
  &:disabled {
    background-color: #e8f0fe;
  }
`;

const Check = styled.button`
  height: calc(1.5em + 0.75rem + 2px);
  color: #1cc88a;
  line-height: 0;

  background-color: transparent;
  cursor: pointer;
  padding: 1.5rem 1rem;
  font-size: 0.8rem;
  border: 1px solid #d1d3e2;
  border-radius: 10rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover:not([disabled]) {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
  }
  &:disabled {
    cursor: default;
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
    opacity: 0.65;
  }
`;

interface UserInput {
  last_name: string;
  first_name: string;
  email: string;
  phone: string;
  auth_code: string;
}

interface pwConfirm {
  pw: string;
  pw_confirm: string;
}

const SignUp = () => {
  const kakao = JSON.parse(localStorage.getItem("kakaoInfo") || "{}");
  const [inputData, setInputData] = useState<UserInput>({
    last_name: "",
    first_name: "",
    email: "",
    phone: "",
    auth_code: "",
  });
  const [password, setPassword] = useState<pwConfirm>({
    pw: "",
    pw_confirm: "",
  });

  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState([false, false]);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const hashPassword = CryptoJS.SHA256(password.pw).toString();
  const params = {
    ...inputData,
    password: hashPassword,
    login_type: "EMAIL",
    fcm_token: "FCM_TOKEN",
    email_marketing_yn: "Y",
  };

  const emailCheck = async () => {
    try {
      await axios.get(
        process.env.REACT_APP_WALLET_API +
          `/v1/api/accounts/email/${inputData.email}`
      );
      setDisabled([disabled[0], true]);
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const register = async () => {
    if (disabled[0]) {
      if (!inputData.first_name || !inputData.last_name) {
        alert("이름을 입력해주세요.");
      } else {
        if (kakao.sns_id) {
          const params = { ...inputData, ...kakao, email_marketing_yn: "Y" };
          try {
            await axios.post(
              process.env.REACT_APP_WALLET_API + "/v1/api/accounts",
              params
            );
            alert("회원가입 완료되었습니다.");
            localStorage.removeItem("kakaoInfo");
            navigate("/");
          } catch (e: any) {
            alert(e.response.data.message);
          }
        } else {
          if (disabled[1]) {
            if (password.pw === password.pw_confirm) {
              try {
                await axios.post(
                  process.env.REACT_APP_WALLET_API + "/v1/api/accounts",
                  params
                );
                alert("회원가입 완료되었습니다.");
                navigate("/");
              } catch (e: any) {
                alert(e.response.data.message);
              }
            } else alert("비밀번호가 일치하지 않습니다.");
          } else alert("이메일 중복 여부를 확인해주세요.");
        }
      }
    } else {
      alert("전화번호 인증이 완료되지 않았습니다.");
    }
  };

  return (
    <Card>
      <Container>
        <Title>Sign Up</Title>
        <Input
          type="text"
          name="last_name"
          placeholder="Last Name"
          onChange={handleChange}
        ></Input>
        <Input
          type="text"
          name="first_name"
          placeholder="First Name"
          onChange={handleChange}
        ></Input>
        <InputBox>
          <Inputs
            disabled={disabled[0]}
            type="text"
            name="phone"
            placeholder="Phone Number"
            onChange={handleChange}
          />
          <Check
            disabled={disabled[0]}
            onClick={() => smsCheck({ inputData, setSuccess })}
          >
            {disabled[0] ? "Confirmed" : "Check"}
          </Check>
        </InputBox>

        {success && (
          <InputBox>
            <Inputs
              type="text"
              name="auth_code"
              placeholder="Phone Confirm Code"
              onChange={handleChange}
            />
            <Check
              onClick={() =>
                confirm({ inputData, disabled, setSuccess, setDisabled })
              }
            >
              Confirm
            </Check>
          </InputBox>
        )}
        {!kakao.sns_id && (
          <>
            <InputBox>
              <Inputs
                disabled={disabled[1]}
                type="email"
                name="email"
                placeholder="Email Address"
                onChange={handleChange}
              />
              <Check disabled={disabled[1]} onClick={emailCheck}>
                {disabled[1] ? "Confirmed" : "Check"}
              </Check>
            </InputBox>
            <Input
              type="password"
              name="pw"
              placeholder="Password"
              onChange={handlePassword}
            />
            <Input
              type="password"
              name="pw_confirm"
              placeholder="Confirm Password"
              onChange={handlePassword}
            />
          </>
        )}
        <Btn onClick={register} as="button">
          Regist Account
        </Btn>
        <LightDark
          to="/"
          onClick={() => localStorage.removeItem("kakaoInfo")}
          mt="8px"
        >
          Back to Login
        </LightDark>
      </Container>
    </Card>
  );
};

export { InputBox, Inputs, Check };
export default SignUp;
