import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Transfer from "./Transfer";
import TransferResult from "./TransferResult";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 480px;
  height: max-content;
  border-radius: 0.3rem;
  background-color: #fff;
  transform: translate(-50%, -50%);
`;

interface ModalProps {
  setModal: Dispatch<SetStateAction<boolean>>;
  setLogedIn: Dispatch<SetStateAction<boolean>>;
  setExpiration: Dispatch<SetStateAction<boolean>>;
  getList(): void;
}

const Modal = ({
  setModal,
  setLogedIn,
  setExpiration,
  getList,
}: ModalProps) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const header = {
    headers: { Authorization: `Bearer ${userInfo.access_token}` },
  };
  const [status, setStatus] = useState("transfer");
  const [address, setAddress] = useState("");
  const [resetTimer, setResetTimer] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (new Date(userInfo.expiration) <= new Date()) {
      setExpiration(true);
    }
    // const params = { to_address: address };

    // const handleTransfer = async () => {
    //   try {
    //     const res = await axios.post(
    //       process.env.REACT_APP_WALLET_API + "/v2/api/transfers",
    //       params,
    //       header
    //     );
    //     res.data.code === 0 && setStatus("result");
    //   } catch (e: any) {
    //     alert(e.response.data.message);
    //     setStatus("transfer");
    //   }
    // };

    // if (status === "transfered") {
    //   handleTransfer();
    // }
  }, [status]);

  useEffect(() => {
    if (resetTimer) {
      setResetTimer(false);
    } else {
      const countdown = setTimeout(() => {
        alert(
          "10분 동안 서비스 이용이 없어 로그아웃 되었습니다. \n다시 로그인하세요."
        );
        localStorage.removeItem("userInfo");
        setLogedIn(false);
        navigate("/");
      }, 600000);
      return () => clearTimeout(countdown);
    }
  }, [resetTimer]);

  return (
    <ModalBackground onMouseMove={() => setResetTimer(true)}>
      <Container>
        {status === "transfer" && (
          <Transfer
            setModal={setModal}
            setStatus={setStatus}
            setAddress={setAddress}
            address={address}
          />
        )}
        {status === "result" && (
          <TransferResult getList={getList} setModal={setModal} />
        )}
      </Container>
    </ModalBackground>
  );
};

export default Modal;
