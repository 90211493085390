import styled from "styled-components";
import Login from "./Login";
import SignUp from "./SignUp";
import Password from "./Password";
import { Route, Routes, useLocation } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";

const Body = styled.div`
  height: 100%;
  min-height: 710px;
  background-color: #5a5c69;
  background-image: linear-gradient(180deg, #5a5c69 10%, #373840 100%);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 906px;
  height: max-content;
  display: flex;
  background-color: #fff;
  border-radius: 0.35rem;
  @media screen and (max-width: 992px) {
    width: 524px;
  }
`;

const CardImage = styled.div<{ imgUrl: string }>`
  width:50%;
  background: url(${(props) => props.imgUrl});
  background-position: center;
  background-size: cover;
  border-radius: 0.35rem 0 0px 0.35rem;
  }
  @media screen and (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

interface AccountProps {
  setLogedIn: Dispatch<SetStateAction<boolean>>;
}

const Account = ({ setLogedIn }: AccountProps) => {
  const location = useLocation();

  return (
    <Body>
      <Container>
        {location.pathname === "/" ? (
          <CardImage imgUrl="https://source.unsplash.com/moL-kDg0y3w/600x800" />
        ) : (
          <CardImage imgUrl="https://source.unsplash.com/random" />
        )}
        <Routes>
          <Route path="/" element={<Login setLogedIn={setLogedIn} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/password" element={<Password />} />
        </Routes>
      </Container>
    </Body>
  );
};

export default Account;
