import styled from "styled-components";
import { FaWonSign, FaPercentage } from "react-icons/fa";

const Container = styled.div`
  float: left;
  flex: 1 1 0;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;

  @media screen and (max-width: 1200px) {
    flex: none;
    width: 50%;
  }

  @media screen and (max-width: 840px) {
    width: 100%;
  }
`;

const CardBody = styled.div`
  word-wrap: break-word;
  border-left: 0.25rem solid ${(props) => props.color || "#ff9b35"} !important;
  padding: 0.5rem 0;
  height: 100%;
  line-height: 1.5;
  background-color: #fff;

  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);

  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
`;

const ContentBox = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;

  .row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  svg {
    color: #dddfeb;
    font-size: 2em;
  }
`;

const Content = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  font-weight: 700;
  width: 100%;

  .name {
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
    color: ${(props) => props.color || "#ff9b35"};
  }
  .data,
  .each {
    font-size: 1.25rem;
    color: #5a5c69;
  }
  .each {
    font-weight: 400;
  }

  span {
    font-size: 1rem;
  }
`;

interface CardProps {
  data: string;
  each?: number;
  children: string;
}

const Card = ({ data, each, children }: CardProps) => {
  return (
    <Container>
      <CardBody>
        <ContentBox>
          <div className="row">
            <Content>
              <div className="name">{children}</div>
              <div className="data">
                {data}{" "}
                {children === "와플수량" || children === "출금가능수량" ? (
                  <span>WAFL</span>
                ) : null}
              </div>
              {children === "현재 평가금액" || children === "매수 금액" ? (
                <div className="each">
                  (개당 : ₩{each ? each?.toLocaleString("ko-KR") : null})
                </div>
              ) : null}
            </Content>
            {children === "수익률" ? (
              <FaPercentage size="36" />
            ) : children === "와플수량" ||
              children === "출금가능수량" ? null : (
              <FaWonSign size="36" />
            )}
          </div>
        </ContentBox>
      </CardBody>
    </Container>
  );
};

export { CardBody, ContentBox, Content };
export default Card;
