import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Container, Title, Input, Btn, LightDark } from "./Login";
import { InputBox, Inputs, Check } from "./SignUp";
import CryptoJS from "crypto-js";
import axios from "axios";

interface pwInput {
  email: string;
  phone: string;
  auth_code: string;
}

interface pwConfirm {
  pw: string;
  pw_confirm: string;
}

const Password = () => {
  const [inputData, setInputData] = useState<pwInput>({
    email: "",
    phone: "",
    auth_code: "",
  });

  const [password, setPassword] = useState<pwConfirm>({
    pw: "",
    pw_confirm: "",
  });

  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const emailCheck = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_WALLET_API + "/v1/api/auths/email",
        inputData
      );
      alert(
        "해당 이메일로 인증코드를 발송했습니다. \n아래에 코드를 입력해주세요."
      );
      setSuccess(true);
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const confirm = async () => {
    try {
      await axios.patch(
        process.env.REACT_APP_WALLET_API + "/v1/api/auths/email",
        inputData
      );
      alert("이메일 인증이 완료되었습니다.");
      setSuccess(false);
      setDisabled(true);
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  const reset = async () => {
    if (password.pw === password.pw_confirm) {
      try {
        const hashPassword = CryptoJS.SHA256(password.pw).toString();
        const params = { ...inputData, password: hashPassword };
        await axios.patch(
          process.env.REACT_APP_WALLET_API + "/v1/api/auths/password",
          params
        );
        alert("비밀번호 변경이 완료되었습니다. \n다시 로그인해주세요.");
        navigate("/");
      } catch (e: any) {
        alert(e.response.data.message);
      }
    } else alert("비밀번호가 일치하지 않습니다.");
  };

  return (
    <Card>
      <Container>
        <Title>Reset Password</Title>
        <InputBox>
          <Inputs
            disabled={disabled}
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={handleChange}
          />
          <Check disabled={disabled} onClick={emailCheck}>
            {disabled ? "Confirmed" : "Check"}
          </Check>
        </InputBox>

        {success && (
          <InputBox>
            <Inputs
              type="text"
              name="auth_code"
              placeholder="Email Confirm Code"
              onChange={handleChange}
            />
            <Check onClick={confirm}>Confirm</Check>
          </InputBox>
        )}

        {disabled && (
          <>
            <Input
              type="text"
              name="phone"
              placeholder="Phone Number"
              onChange={handleChange}
            />
            <Input
              type="password"
              name="pw"
              placeholder="Password"
              onChange={handlePassword}
            />
            <Input
              type="password"
              name="pw_confirm"
              placeholder="Confirm Password"
              onChange={handlePassword}
            />
            <Btn as="button" onClick={reset}>
              Reset Password
            </Btn>
          </>
        )}
        <LightDark to="/" mt={disabled ? "8px" : ""}>
          Back to Login
        </LightDark>
      </Container>
    </Card>
  );
};

export default Password;
