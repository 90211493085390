import styled from "styled-components";
import { Link } from "react-router-dom";
import { FaChartArea, FaAngleLeft, FaWallet } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { useState, useEffect, Dispatch, SetStateAction } from "react";

const NavBar = styled.ul<{ btn: boolean; toggle: boolean }>`
  min-height: 100vh;
  width: ${(props) => (props.toggle ? "0" : props.btn ? "6.5rem" : "14rem")};
  margin: 0;
  display: flex;
  flex-direction: column;

  color: #858796;
  padding-left: 0;
  )list-style: none;
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  background-size: cover;

  // @media screen and (max-width: 768px) {
  //   width: 6.5rem;
  // }

  // @media screen and (max-width: 480px) {
  //   width: 0;
  // }
`;

const Li = styled.li<{ $btn?: boolean }>`
  svg {
    font-size: 0.85rem;
    vertical-align: ${(props) => (props.$btn ? "" : "middle")};
    color: rgba(255, 255, 255, 0.3);
    width: 1.25em;
    margin-right: ${(props) => (props.$btn ? "" : "0.25rem")};
  }
  span {
    vertical-align: middle;
    line-height: 1.5;
    font-size: 1.2rem;
    display: inline;
    color: rgba(255, 255, 255, 0.8);
  }
  &:hover svg,
  &:hover span {
    color: #fff;
  }
`;

const NavLink = styled(Link)<{ $btn?: boolean }>`
  display: block;
  width: ${(props) => (props.$btn ? "6.5rem" : "14rem")};
  padding: ${(props) => (props.$btn ? "0.75rem 1rem" : "1rem")};
  text-align: ${(props) => props.$btn && "center"};
  text-decoration: none;

  span {
    font-size: ${(props) => props.$btn && "1rem"};
    display: ${(props) => props.$btn && "block"};
    word-break: keep-all;
  }
`;

const LogoLink = styled(NavLink)<{ $btn?: boolean }>`
  display: flex;
  height: 4.375rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
`;

const LogoName = styled.div<{ $btn?: boolean }>`
  color: #fff;
  margin: 0 1rem;
  font-size: 1rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  display: ${(props) => props.$btn && "none"};
`;

const Hr = styled.hr<{ mg?: string }>`
  margin: ${(props) => props.mg || "0 1rem 1rem"};
  box-sizing: content-box;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
`;

const Heading = styled.div<{ btn: boolean }>`
  color: rgba(255, 255, 255, 0.4);
  padding: 0 1rem;
  font-weight: 800;
  font-size: 0.65rem;
  text-align: ${(props) => props.btn && "center"};
`;

const ToggleBtn = styled.button<{ btn: boolean }>`
  background-color: rgba(255, 255, 255, 0.2);
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 50%;
  border: 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: rgba(255, 255, 255, 0.5);
    width: 8px;
    height: 16px;
    transform: ${(props) => props.btn && "rotate(180deg)"};
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

interface SideBarProps {
  toggle: boolean;
  setSideToggle: Dispatch<SetStateAction<boolean>>;
}

const SideBar = ({ toggle, setSideToggle }: SideBarProps) => {
  const [btn, setBtn] = useState(false);

  const btnClickHandle = () => {
    setBtn(!btn);
  };

  const resizeHandler = () => {
    if (window.innerWidth < 900) {
      setBtn(true);
    }
    if (window.innerWidth > 480) {
      setSideToggle(false);
    } else {
      setSideToggle(true);
    }
  };

  useEffect(() => {
    if (window.innerWidth < 900) {
      setBtn(true);
    }
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <NavBar btn={btn} toggle={toggle}>
      <LogoLink to="/" $btn={btn}>
        <img src="/logo.png" alt="logo" width="32" height="32" />
        <LogoName $btn={btn}>
          WALLET <sup style={{ fontSize: "75%" }}>WAFL</sup>
        </LogoName>
      </LogoLink>
      <Hr />
      <Heading btn={btn}>MY WALLET</Heading>
      <Li $btn={btn}>
        <NavLink $btn={btn} to="/">
          <FaWallet />
          <span>지갑</span>
        </NavLink>
      </Li>
      <Hr />
      <Heading btn={btn}>WITHDRAW</Heading>
      <Li $btn={btn}>
        <NavLink $btn={btn} to="/withdraw">
          <FiSend />
          <span>출금 신청</span>
        </NavLink>
      </Li>
      <Hr />
      <Heading btn={btn}>HISTORY</Heading>
      <Li $btn={btn}>
        <NavLink $btn={btn} to="/deposit">
          <FaChartArea />
          <span>입출금 내역</span>
        </NavLink>
      </Li>
      <Hr />
      <div className="btnBox" style={{ textAlign: "center" }}>
        <ToggleBtn btn={btn} onClick={btnClickHandle}>
          <div>
            <FaAngleLeft />
          </div>
        </ToggleBtn>
      </div>
    </NavBar>
  );
};

export default SideBar;
