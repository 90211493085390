import { useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { smsCheck, confirm } from "../helper/api";

const Header = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #2e59d9;
  margin: 1rem 0;
`;

export const Close = styled(FaTimes)`
  position: absolute;
  padding: 5px 5px;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
`;

export const Content = styled.div<{
  $dp?: boolean;
  $jc?: boolean;
  $black?: boolean;
  $warning?: boolean;
}>`
  color: ${(props) => (props.$black ? "#000" : "#767676")};
  padding: 1rem 1.3rem;
  display: ${(props) => props.$dp && "flex"};
  justify-content: ${(props) => props.$jc && "space-between"};
  font-size: 1.1rem;

  h1 {
    font-size: 2rem;
    background-color: #e8e8e8;
    padding: 1.4rem;
    border-radius: 0.5rem;
    margin-bottom: 0 !important;
    text-align: center;
    font-weight: 700 !important;
    word-break: break-word;
    span {
      font-size: 1.3rem;
      margin-left: 0.35rem;
      word-break: keep-all;
    }
  }

  .amount {
    font-weight: 600;
  }
  span {
    font-size: 0.9rem;
  }
  .box {
    display: flex;
    align-items: center;
    color: ${(props) => props.$warning && "#ee5d5d"};
  }
  input {
    width: 100%;
    height: 2rem;
    padding: 0 0.5rem;
    margin-top: 0.8rem;
    border-radius: 0.2rem;
    border: 1px solid #d1d3e2;
  }
  input:focus {
    box-shadow: 0 0 0 0.2rem #8ca3e3;
    outline: 0;
  }
`;

const SelectBox = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 0.5rem;
  margin: 0.8rem 0;
  border-radius: 0.2rem;
  border: 1px solid #d1d3e2;

  select {
    width: 100%;
    border: none;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  color: #fff;
  background-color: #2653d4;
  border: 1px solid #2653d4;
  cursor: pointer;

  &:hover {
    background-color: #2e59d9;
    border-color: #2653d4;
  }
`;

const InputBox = styled.div`
  display: flex;
`;

const AuthButton = styled.button<{ $bg?: boolean }>`
  width: 40%;
  height: 2rem;
  margin: 0.8rem 0 0 0.5rem;
  background-color: ${(props) => (props.$bg ? "#ed4555" : "#4e73df")};
  border-radius: 0.3rem;
  color: #fff;
  cursor: pointer;
  border: 1px solid ${(props) => (props.$bg ? "#ed4555" : "#4e73df")};
`;

const Notice = styled.ul`
  font-size: 0.9rem;
  padding: 0 2.4rem;
  li {
    margin-bottom: 0.25rem;
  }
`;

interface TransferProps {
  setModal: Dispatch<SetStateAction<boolean>>;
  setStatus: Dispatch<SetStateAction<string>>;
  setAddress: Dispatch<SetStateAction<string>>;
  address: string;
}

interface UserInput {
  phone: string;
  auth_code: string;
}

const Transfer = ({
  setModal,
  setStatus,
  setAddress,
  address,
}: TransferProps) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const header = {
    headers: { Authorization: `Bearer ${userInfo.access_token}` },
  };
  const [checked, setChecked] = useState(false);
  const [inputData, setInputData] = useState<UserInput>({
    phone: "",
    auth_code: "",
  });
  const [type, setType] = useState("");

  const handleAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const requestWithdraw = async () => {
    if (type === "") {
      alert("개인지갑 또는 거래소를 선택하세요.");
      return;
    }
    if (!checked) {
      alert("전화번호 인증이 완료되지 않았습니다.");
      return;
    }

    if (address.length === 0) {
      alert("지갑 주소를 입력하세요.");
      return;
    }
    const params = {
      to_address: address,
      to_type: type,
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_WALLET_API + `/v2/api/transfers`,
        params,
        header
      );
      res.data.code === 0 && setStatus("transfered");
      console.log(res.data.data);
      setStatus("result");
    } catch (e: any) {
      alert(e.response.data.message);
    }
  };

  // const checkAddress = async () => {
  //   if (!checked) {
  //     alert("전화번호 인증이 완료되지 않았습니다.");
  //     return;
  //   }

  //   if (address.length === 0) {
  //     alert("지갑 주소를 입력하세요.");
  //     return;
  //   }
  //   const res = await axios.get(
  //     process.env.REACT_APP_WALLET_API + `/v1/api/wallets/check/${address}`
  //   );
  //   const status = res.data.data.wallet_status;

  //   if (status) {
  //     setStatus("transfered");
  //   } else {
  //     alert("유효하지 않은 주소입니다. 지갑 주소를 확인하세요.");
  //   }
  // };

  return (
    <>
      <Header>
        출금신청
        <Close onClick={() => setModal(false)} size={30} />
      </Header>
      <Content>
        <form>
          <label style={{ display: "block" }}>개인 지갑 및 거래소 선택</label>
          <SelectBox>
            <select onChange={(e) => setType(e.target.value)}>
              <option value="" style={{ color: "#767676" }}>
                -- 개인지갑 또는 거래소 선택 --
              </option>
              <option value="GDAC">GDAC</option>
              <option value="PRIVATE">개인지갑(메타마스크)</option>
            </select>
          </SelectBox>

          <label style={{ display: "block" }}>출금 지갑 주소</label>
          <input
            type="text"
            name="adress"
            placeholder="지갑 주소를 입력하세요"
            onChange={handleAddress}
          />
        </form>
      </Content>

      {checked ? null : (
        <Content>
          <label style={{ display: "block" }}>휴대폰 본인확인</label>
          <InputBox>
            <input
              type="text"
              name="phone"
              onChange={handleChange}
              placeholder="휴대폰 번호 입력"
            />
            <AuthButton $bg onClick={() => smsCheck({ inputData })}>
              문자인증
            </AuthButton>
          </InputBox>
          <InputBox>
            <input
              type="text"
              name="auth_code"
              onChange={handleChange}
              placeholder="인증번호 입력"
            />
            <AuthButton onClick={() => confirm({ inputData, setChecked })}>
              확인
            </AuthButton>
          </InputBox>
        </Content>
      )}

      <Notice>
        <li>출금가능시간 : 평일 10시 ~ 18시</li>
        <li>
          출금가능시간 이후 출금신청된 건은 익일 처리됩니다. (단, 토/일요일
          신청시 월요일에 처리됩니다.)
        </li>
        <li style={{ letterSpacing: "-0.3px" }}>
          메인넷 변경이 안 된 해외거래소로 출금은 되지 않습니다.
          <br /> 해외거래소 출금주소 입력 시 회수가 안 되는 경우가 발생합니다.
        </li>
        <li>부정신청이 의심될 경우 출금이 제한될 수 있습니다.</li>
        <li style={{ color: "#ff0000" }}>
          현재 GDAC 거래소의 입출금 서비스 일시중단으로 메타마스크로만 출금
          가능합니다. (추후 GDAC 거래소의 입출금 서비스가 재개될 경우 신청
          가능합니다.)
        </li>
      </Notice>

      <Content>
        <Button onClick={requestWithdraw}>출금 신청</Button>
      </Content>
    </>
  );
};

export default Transfer;
