import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MyWallet from "./MyWallet";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";

const Container = styled.div`
  padding: 0 1.5rem;
  width: 100%;

  h1 {
    color: #5a5c69;
    font-size: 1.75rem;
    font-weight: 400;
    margin: 0 0 24px;
  }
`;

interface Balance {
  wallet_address?: string;
  wallet_balance?: { ETH?: string; WAFL?: string };
}

interface ContentMainProps {
  balance: Balance;
  notExist: Boolean;
  setBalance: Dispatch<SetStateAction<{}>>;
  setNotExist: Dispatch<SetStateAction<boolean>>;
  setLogedIn: Dispatch<SetStateAction<boolean>>;
}

const ContentMain = ({
  balance,
  notExist,
  setBalance,
  setNotExist,
  setLogedIn,
}: ContentMainProps) => {
  const navigate = useNavigate();
  const [expiration, setExpiration] = useState(false);

  useEffect(() => {
    if (expiration) {
      alert("인증이 만료되었습니다. 다시 로그인하세요.");
      localStorage.removeItem("userInfo");
      setLogedIn(false);
      navigate("/");
    }
  }, [expiration]);

  return (
    <Container>
      <Routes>
        <Route
          path="/"
          element={
            <MyWallet
              balance={balance}
              notExist={notExist}
              setBalance={setBalance}
              setNotExist={setNotExist}
              setExpiration={setExpiration}
            />
          }
        />
        <Route
          path="/deposit"
          element={<Deposit setExpiration={setExpiration} />}
        />
        <Route
          path="/withdraw"
          element={
            <Withdraw setExpiration={setExpiration} setLogedIn={setLogedIn} />
          }
        />
      </Routes>
    </Container>
  );
};

export default ContentMain;
