import React, { useEffect, Dispatch, SetStateAction, useRef } from "react";
import styled from "styled-components";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  position: absolute;
  right: 0;
  left: auto;
  width: auto;
  min-width: 10rem;
  background-color: #fff;
  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
  line-height: 1.5;
  width: auto;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
`;

const Logout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3a3b45;
  padding: 0.25rem 1.5rem;
  font-size: 0.85rem;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }
  svg {
    color: #d1d3e2 !important;
    margin-right: 0.5rem;
  }
  &:hover {
    background-color: #eaecf4;
  }
`;

interface DropDownProps {
  dropDown: boolean;
  dropRef: React.RefObject<HTMLDivElement>;
  setDropdown: Dispatch<SetStateAction<boolean>>;
  setLogedIn: Dispatch<SetStateAction<boolean>>;
}

const Dropdown = ({
  dropDown,
  dropRef,
  setDropdown,
  setLogedIn,
}: DropDownProps) => {
  const navigate = useNavigate();

  const handleClick = (e: Event) => {
    const dropdown = dropRef.current?.contains(e.target as Node);
    const logout = logoutRef.current?.contains(e.target as Node);

    if (!dropdown && !logout) {
      setDropdown(false);
    } else if (logout) {
      localStorage.removeItem("userInfo");
      setLogedIn(false);
      navigate("/");
    }
  };

  const logoutRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dropDown) document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <Container>
      <Logout ref={logoutRef}>
        <div>
          <FaSignOutAlt />
        </div>
        Logout
      </Logout>
    </Container>
  );
};

export default Dropdown;
