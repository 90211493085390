import { useState, Dispatch, SetStateAction, KeyboardEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import CryptoJS from "crypto-js";

const Card = styled.div`
  width: 100%;
  @media screen and(min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;
const Container = styled.div`
  display: flex
  flex-direction: column;
  padding: 48px;
`;
const Title = styled.div`
  text-align: center;
  color: #3a3b45;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;

  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: #858796;
  }
  &:focus {
    color: #6e707e;
    background-color: #fff;
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
  }
`;
const Button = styled(Link)`
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 0.8rem;
  border: 1px solid transparent;
  border-radius: 10rem;
  padding: 0.75rem 1rem;
  color: #fff;
  cursor: pointer;
`;

const Btn = styled(Button)`
  background-color: #4e73df;
  border-color: #4e73df;
  border: none;

  &:hover {
    background-color: #2e59d9;
    border-color: #2e59d9;
  }
  &:foucs {
    background-color: #2e59d9;
    border-color: #2653d4;
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
  }
`;

const KakaoBtn = styled(Button)`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fee500;
  border-color: #fee500;
  color: #191919;
  border: none;

  img {
    width: 1.3rem;
    margin-right: 0.3rem;
  }
  &:hover {
    background-color: #e5d303;
    border-color: "#e5d303;
  }
  &:foucs {
    background-color:#e5d303;
    border-color: "
      #e5d303;
  }
`;

const BtnDark = styled(Button)<{ mt?: string }>`
  margin-top: ${(props) => props.mt || ""};
  background-color: #5a5c69;
  border-color: #5a5c69;
  &:hover {
    color: #fff;
    background-color: #484a54;
    border-color: #42444e;
  }
  &:focus {
    color: #fff;
    background-color: #484a54;
    border-color: #42444e;
    box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
  }
`;

const LightDark = styled(BtnDark)`
  background-color: #858796;

  &:hover {
    background-color: #717384;
    border-color: #6b6d7d;
  }
  &:focus {
    color: #fff;
    background-color: #717384;
    border-color: #6b6d7d;
    box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
  }
`;

interface UserInput {
  email: string;
  password: string;
}

interface LoginProps {
  setLogedIn: Dispatch<SetStateAction<boolean>>;
}

const Login = ({ setLogedIn }: LoginProps) => {
  const [inputData, setInputData] = useState<UserInput>({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const hashPassword = CryptoJS.SHA256(inputData.password).toString();

  const params = {
    email: inputData.email,
    password: hashPassword,
    login_type: "EMAIL",
    fcm_token: "FCM_TOKEN",
  };

  const login = async () => {
    if (inputData.email.length === 0) {
      alert("아이디를 입력하세요");
    } else if (inputData.password.length === 0) {
      alert("비밀번호를 입력하세요");
    } else {
      try {
        const res = await axios.post(
          process.env.REACT_APP_WALLET_API + "/v1/api/accounts/login",
          params
        );
        const data = res.data.data;
        const date = new Date();
        date.setHours(date.getHours() + 12);

        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...data,
            expiration: date,
          })
        );
        if (res.data.code === 0) {
          setLogedIn(true);
        }
      } catch (e: any) {
        alert(e.response.data.message);
      }
    }
  };

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API_KEY;

  const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;

  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

  return (
    <Card>
      <Container>
        <Title>WAFL Wallet</Title>
        <Input
          type="email"
          name="email"
          placeholder="Enter Email Address..."
          onChange={handleChange}
        ></Input>
        <Input
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleChange}
          onKeyPress={onKeyPress}
        ></Input>
        <Btn onClick={login} as="button">
          Login
        </Btn>
        <KakaoBtn
          as="button"
          onClick={() => (window.location.href = KAKAO_AUTH_URL)}
        >
          <img src="/logo_kakao.svg" alt="logo_kakao" />
          <span>카카오로 로그인</span>
        </KakaoBtn>
        <hr
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
            border: "0",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        />
        <BtnDark to="/signup">Create an Account!</BtnDark>
        <BtnDark to="/password" mt="8px">
          Forgot Password?
        </BtnDark>
      </Container>
    </Card>
  );
};

export { Card, Container, Title, Input, Btn, LightDark };
export default Login;
