import { useState, useEffect, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { FaAngleUp } from "react-icons/fa";
import Content from "./Content";
import SideBar from "./SideBar";

const Container = styled.div`
  display: flex;
`;

const ScrollTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 2.75rem;
  height: 2.75rem;
  text-align: center;
  color: #fff;
  background: rgba(90, 92, 105, 0.5);
  line-height: 46px;
  cursor: pointer;
  border-radius: 0.35rem;

  &:hover {
    color: white;
    background: #5a5c69;
  }
`;

interface WalletProps {
  setLogedIn: Dispatch<SetStateAction<boolean>>;
}

const Wallet = ({ setLogedIn }: WalletProps) => {
  const getInfo = localStorage.getItem("userInfo");
  const userInfo = JSON.parse(getInfo || "{}");

  const [sideToggle, setSideToggle] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setScroll(true);
    } else setScroll(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container>
      <SideBar toggle={sideToggle} setSideToggle={setSideToggle} />
      <Content
        toggle={sideToggle}
        setSideToggle={setSideToggle}
        setLogedIn={setLogedIn}
        userInfo={userInfo}
      />
      {scroll && (
        <ScrollTop onClick={scrollToTop}>
          <FaAngleUp />
        </ScrollTop>
      )}
    </Container>
  );
};

export default Wallet;
