import { Dispatch, SetStateAction } from "react";
import axios from "axios";

interface Data {
  inputData: {};
  disabled?: boolean[];
  setSuccess?: Dispatch<SetStateAction<boolean>>;
  setChecked?: Dispatch<SetStateAction<boolean>>;
  setDisabled?: Dispatch<React.SetStateAction<boolean[]>>;
}

const smsCheck = async ({ inputData, setSuccess }: Data) => {
  try {
    const res = await axios
      .post(process.env.REACT_APP_WALLET_API + "/v1/api/auths/sms", inputData)
      .catch();
    const data = res.data;
    if (data.code === 0) {
      if (setSuccess) {
        setSuccess(true);
      }
      alert(
        "해당 전화번호로 인증코드를 발송했습니다. \n아래에 코드를 입력해주세요."
      );
    }
  } catch (e: any) {
    console.log(e);
    alert(e.response.data.message);
  }
};

const confirm = async ({
  inputData,
  disabled,
  setSuccess,
  setChecked,
  setDisabled,
}: Data) => {
  try {
    const res = await axios
      .patch(process.env.REACT_APP_WALLET_API + "/v1/api/auths/sms", inputData)
      .catch();
    const data = res.data;
    if (data.code === 0) {
      if (setSuccess) {
        setSuccess(false);
      }
      if (setDisabled && disabled) {
        setDisabled([true, disabled[1]]);
      }
      if (setChecked) {
        setChecked(true);
      }
      alert("전화번호 인증이 완료되었습니다.");
    }
  } catch (e: any) {
    console.log(e);
    alert(e.response.data.message);
  }
};

export { smsCheck, confirm };
