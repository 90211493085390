import styled from "styled-components";

const Container = styled.div`
  float: left;
  width: 50%;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;

  @media screen and (max-width: 1200px) {
    width: 50%;
  }

  @media screen and (max-width: 840px) {
    width: 100%;
  }
`;

const CardBody = styled.div`
  word-wrap: break-word;
  border-left: 0.25rem solid ${(props) => props.color || "#e27575"} !important;
  padding: 0.5rem 0;
  height: 100%;
  line-height: 1.5;
  background-color: #fff;

  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);

  border: 1px solid #e3e6f0;
  border-radius: 0.35rem;
`;

const ContentBox = styled.div`
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;

  .row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  svg {
    color: #dddfeb;
    font-size: 2em;
  }
`;

const Content = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  font-weight: 700;
  width: 100%;

  .name {
    font-size: 1rem;
    margin-bottom: 0.25rem;
    color: ${(props) => props.color || "#e27575"};
  }
  .data,
  .each {
    font-size: 1.25rem;
    color: #5a5c69;
  }
  .each {
    font-weight: 400;
  }

  span {
    font-size: 1rem;
  }
`;

interface CardProps {
  color?: string;
  data?: string | number;
  children: string;
}

const MediumCard = ({ color, data, children }: CardProps) => {
  return (
    <Container>
      <CardBody color={color}>
        <ContentBox>
          <div className="row">
            <Content color={color}>
              <div className="name">{children}</div>
              <div className="data">
                {data?.toLocaleString("ko-KR")} <span>WAFL</span>
              </div>
            </Content>
          </div>
        </ContentBox>
      </CardBody>
    </Container>
  );
};

export { CardBody, ContentBox, Content };
export default MediumCard;
