import { useEffect, useState, useRef, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import ContentMain from "./ContentMain";
import Dropdown from "./Dropdown";
import Footer from "./Footer";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f9fc;
  width: 100%;
  overflow-x: hidden;

  .wrapper {
    flex: 1 0 auto;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  height: 4.375rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%);
  background-color: #fff;

  .user {
    margin-left: auto;
    display: flex;
  }
  .divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
  }

  @ screen and (max-width: 768px) {
    .divider {
      display: none;
    }
  }
`;

const User = styled.div`
  color: #d1d3e2;
  height: 4.375rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;

  span {
    color: #858796;
    font-size: 80%;
    font-weight: 400;
    margin-right: 0.5rem;
  }

  @media screen and (max-width: 992px) {
    span {
      display: none;
    }
  }
`;

const UserImg = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  vertical-align: middle;
  border-style: none;
`;

const Bars = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  color: #4e73df;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: #eaecf4;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

interface UserInfo {
  last_name: string;
  first_name: string;
  wallet_address: string;
  access_token: string;
}

interface ContentProps {
  userInfo: UserInfo;
  toggle: boolean;
  setSideToggle: Dispatch<SetStateAction<boolean>>;
  setLogedIn: Dispatch<SetStateAction<boolean>>;
}

const Content = ({
  userInfo,
  toggle,
  setSideToggle,
  setLogedIn,
}: ContentProps) => {
  const [dropDown, setDropdown] = useState(false);
  const [balance, setBalance] = useState({});
  const [notExist, setNotExist] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const dropDownClick = () => {
    setDropdown(!dropDown);
  };

  const getBalance = async () => {
    const header = {
      headers: { Authorization: `Bearer ${userInfo.access_token}` },
    };

    try {
      const res = await axios.get(
        process.env.REACT_APP_WALLET_API + "/v1/api/wallets",
        header
      );
      setBalance(res.data.data);
    } catch {
      setNotExist(true);
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <Container>
      <div className="wrapper">
        <Nav>
          <Bars
            onClick={() => {
              setSideToggle(!toggle);
            }}
          >
            <FaBars style={{ width: "14px", height: "16px" }} />
          </Bars>
          <div className="user">
            <div className="divider"></div>
            <div style={{ position: "relative", cursor: "pointer" }}>
              <User onClick={dropDownClick} ref={ref}>
                <span>{userInfo.last_name + " " + userInfo.first_name}</span>
                <UserImg src="/undraw_profile.svg" />
              </User>
              {dropDown && (
                <Dropdown
                  dropDown={dropDown}
                  dropRef={ref}
                  setDropdown={setDropdown}
                  setLogedIn={setLogedIn}
                />
              )}
            </div>
          </div>
        </Nav>
        <ContentMain
          balance={balance}
          notExist={notExist}
          setBalance={setBalance}
          setNotExist={setNotExist}
          setLogedIn={setLogedIn}
        />
      </div>
      <Footer></Footer>
    </Container>
  );
};

export default Content;
