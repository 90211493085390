import { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Table, Card, CardBody } from "./Deposit";
import Modal from "./Modal";
import axios from "axios";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1.3rem 1.3rem 0 0;

  @media screen and (max-width: 480px) {
    margin: 1rem 1rem 0 0;
  }
`;

const Button = styled.button`
  width: 12rem;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  color: #fff;
  background-color: #2653d4;
  border: 1px solid #2653d4;
  cursor: pointer;

  &:hover {
    background-color: #2e59d9;
    border-color: #2653d4;
  }

  @media screen and (max-width: 480px) {
    width: 7rem;
  }
`;

interface WithdrawProps {
  setLogedIn: Dispatch<SetStateAction<boolean>>;
  setExpiration: Dispatch<SetStateAction<boolean>>;
}

interface List {
  to_address: string;
  crypto_value: string;
  deposit_status: string;
}

export default function Withdraw({ setLogedIn, setExpiration }: WithdrawProps) {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const header = {
    headers: { Authorization: `Bearer ${userInfo.access_token}` },
  };
  const [modal, setModal] = useState(false);
  const [list, setList] = useState<List[]>();

  const getList = async () => {
    const res = await axios.get(
      process.env.REACT_APP_WALLET_API + "/v1/api/transfers/withdraw/list",
      header
    );
    setList(res.data.data.list);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      {modal && (
        <Modal
          getList={getList}
          setLogedIn={setLogedIn}
          setModal={setModal}
          setExpiration={setExpiration}
        />
      )}
      <h1>Withdraw</h1>
      <Card>
        <Container>
          <Button
            onClick={() => {
              setModal(true);
            }}
          >
            출금 신청
          </Button>
        </Container>

        <div style={{ overflow: "auto" }}>
          <CardBody>
            <Table br="0" mt="0">
              <thead>
                <tr>
                  <td>출금 주소</td>
                  <td>수량</td>
                  <td>진행 상태</td>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.to_address}</td>
                    <td>
                      {item.crypto_value === null
                        ? ""
                        : parseInt(item.crypto_value).toLocaleString("ko-KR")}
                    </td>
                    <td>
                      {item.deposit_status === "REQ"
                        ? "출금 진행중"
                        : item.deposit_status === "SEND"
                        ? "출금 완료"
                        : "출금 거절"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </div>
      </Card>
    </>
  );
}
