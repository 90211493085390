import styled from "styled-components";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import animationData from "../success-icon.json";
import { Close } from "./Transfer";
import { Dispatch, SetStateAction, useRef, useEffect } from "react";

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  color: #2e59d9;
  margin: 4rem 0;
`;

const CheckMark = styled(Lottie)`
  width: 6rem;
  height: 6rem;
  margin: auto;
  margin-top: 5rem;
  margin-bottom: 7.5rem;
`;

interface TransferResultProps {
  setModal: Dispatch<SetStateAction<boolean>>;
  getList(): void;
}

const TransferResult = ({ setModal, getList }: TransferResultProps) => {
  const lottieRef = useRef<LottieRefCurrentProps>();
  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(1.6);
    }
  }, []);

  const handleClose = () => {
    getList();
    setModal(false);
  };

  return (
    <>
      <Close size={30} onClick={handleClose} />
      <Title>출금 신청 완료</Title>
      <CheckMark
        animationData={animationData}
        lottieRef={lottieRef as any}
        loop={false}
      />
    </>
  );
};

export default TransferResult;
