import axios from "axios";
import { useEffect } from "react";

const Auth = () => {
  const code = new URL(window.location.href).searchParams.get("code");

  const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API_KEY;
  const REDIRECT_URI = process.env.REACT_APP_KAKAO_REDIRECT_URI;

  const getToken = async () => {
    const header = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    try {
      const res = await axios.post(
        "https://kauth.kakao.com/oauth/token",
        `grant_type=authorization_code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${code}`,
        header
      );
      const ACCESS_TOKEN = res.data.access_token;
      const header2 = {
        headers: { Authorization: `Bearer ${ACCESS_TOKEN}` },
      };
      try {
        const response = await axios.get(
          "https://kapi.kakao.com/v2/user/me",
          header2
        );
        const data = response.data;
        const params = {
          email: data.kakao_account.email,
          sns_id: `${data.id}`,
          login_type: "KAKAO",
          fcm_token: "FCM_TOKEN",
        };
        try {
          const res = await axios.post(
            process.env.REACT_APP_WALLET_API + "/v1/api/accounts/login",
            params
          );
          const data = res.data.data;
          const date = new Date();
          date.setHours(date.getHours() + 12);

          localStorage.setItem(
            "userInfo",
            JSON.stringify({
              ...data,
              expiration: date,
            })
          );
          if (res.data.code === 0) {
            window.location.href = "/";
          }
        } catch (e: any) {
          if (e.response.data.code === 1003) {
            localStorage.setItem("kakaoInfo", JSON.stringify(params));
            window.location.href = "/signup";
          }
        }
      } catch (e: any) {
        console.log(e);
      }
    } catch (e: any) {
      console.log(e);
    }
  };
  useEffect(() => {
    getToken();
  }, []);
};

export default Auth;
