import styled from "styled-components";
import { CardBody, ContentBox, Content } from "./Card";
import { FaEthereum, FaCopy } from "react-icons/fa";

const Container = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 0 0.75rem;
`;

const Copy = styled.button`
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 0.35rem;
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #4e73df;
  border-color: #4e73df;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
 
    &:hover {
      background-color: #2e59d9;
      border-color: #2653d4;
    }
  }
`;

interface WideCardProps {
  color: string;
  data?: string;
  children: string;
}

const WideCard = ({ color, data, children }: WideCardProps) => {
  const copyAddress = () => {
    navigator.clipboard.writeText(`${data}`).then(() => {
      alert("주소가 복사 되었습니다.");
    });
  };

  return (
    <Container>
      <CardBody color={color}>
        <ContentBox>
          <div className="row">
            <Content color={color}>
              <div className="name">{children}</div>
              <div className="data">{data}</div>
            </Content>
            {children === "ADDRESS" && data && (
              <Copy onClick={copyAddress}>복사</Copy>
            )}
            {children === "ADDRESS" ? (
              <FaCopy style={{ marginLeft: "auto" }} />
            ) : (
              <FaEthereum size="36" />
            )}
          </div>
        </ContentBox>
      </CardBody>
    </Container>
  );
};

export default WideCard;
